<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ContactoClienteViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [
      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        delete: {
          title: 'Eiminar',
          visible: true,
          icon: 'delete'
        },
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcontacto_cliente
        item.title = this.$online.contactoCliente.title(item)
        item.subtitle = this.$online.contactoCliente.subtitle(item)
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.descripcion
      this.toolbarOptions.delete.visible = this.hasDeletePerm(this.$route.meta.permission.idobjeto)
      this.moreInfoRows = [
        { name: 'canal_comunicacion_desc', label: 'Canal de comunicación:' },
        { name: 'observaciones', label: 'Observaciones:' },
        { name: 'nombreempleado', label: 'Empleado:' },
        { name: 'tcontacto_desc', label: 'Tipo de contacto:' },
      ]
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectContactoCliente(
          this, this.routeParams.idcontacto_cliente
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idcontacto_cliente: this.routeParams.idcontacto_cliente
        },
      })
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.delete) {
        const res = await this.$alert.showConfirm(
          `¿Deseas eliminar el contacto "${this.item.dataset.descripcion}"?`
        )
        if (res) {
          this.$loading.showManual('Eliminando...')
          try {
            await this.$online.contactoCliente.delete(this.routeParams.idcontacto_cliente)
            await this.$dirty.deleted(this.$dirty.ENTITIES.remote.contactoCliente, this.routeParams.idcontacto_cliente)
          } finally {
            this.$loading.hide()
          }
          this.$appRouter.go(-1)
        }
      }
    },
  },
}
</script>
