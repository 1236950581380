import { APIFilter } from '@/utils/api'

export default {
  async selectContactoCliente (Vue, idcontactoCliente) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idcontacto_cliente', idcontactoCliente)
    const resp = await Vue.$api.call('contactoCliente.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
